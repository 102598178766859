import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'gatsby'

const Imprint = (): React.ReactElement => (
  <>
    <Header />

    <main className="main">
      <section className="imprint">
        <div className="imprint__header">
          <h1 className="imprint__title">
          Imprint qibri
          </h1>
          <p className="imprint__subtitle">Offenlegung gemäß 14 UGB § & § 5 E-Commerce-Gesetz</p>
        </div>
        <div>
          <p>
            qibri Gmbh<br></br>{''}
            Untere Weißgerberstraße 43/6<br></br>{''}
            1030 Wien, Österreich<br></br>{''}
            Telefon: + 43-1-997-2840<br></br>{''}
            Fax: + 43-1-997-2840-88<br></br>{''}
            E-Mail: contact (at) qibri.com<br></br>{''}
          </p>
          <p>Sitz der Gesellschaft: Wien<br></br>{''}
            Registernummer: FN390030z<br></br>{''}
            UID: ATU 67677613<br></br>{''}
            Gesellschaftszweck: Unternehmensberatung<br></br>
            Kammerzugehörigkeit: Fachgruppe Unternehmensberatung und Informationstechnologie {''}
            www.ubit.at<br></br>{''}
            Geschäftsführer: Michael Siegmund
          </p>

          <Link to="/" title="Back to the qibri homepage">Back to start</Link>
        </div>
      </section>

    </main>

    <Footer />
  </>
)
export default Imprint
